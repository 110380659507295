import texts from '../../../texts';
import styles from './styles.module.scss';

export default function Footer({ dairy = false }) {
	const { legal, rulesText, rulesLink, rulesLinkDairy, privacyPolicyText, privacyPolicyLink, copy, groznovLinkName, groznovLinkAdress } = texts.footer;
	return (
		<section className={styles.footer}>
			<div className={styles.footerLegal}>
				<span className={styles.footerLegalText}>{legal[0]}</span>
				<a className={styles.footerLegalText} href={dairy ? rulesLink : rulesLinkDairy} target="_blank">{rulesText}</a>
				<span className={styles.footerLegalText}>{legal[1]}</span>
				<a className={styles.footerLegalText} href={privacyPolicyLink} target="_blank">{privacyPolicyText}</a>
				<span className={styles.footerLegalText}>{legal[2]}</span></div>
			<div className={styles.footerCopy}>
				<span>{copy}</span>
				<a className={styles.footerCopyLink} target="_blank" href={groznovLinkAdress}>
					{groznovLinkName}
				</a><sup>&reg;</sup>
			</div>
		</section>
	);
}
