import React, { useState } from 'react';
import { Check, Copy } from '../../icons';
import styles from './styles.module.scss';

export default function Promocode({ code }) {
	const [isCopied, setIsCopied] = useState(false);

	const onClickHandle = async () => {
		try {
			await navigator.clipboard.writeText(code);
			setIsCopied(true);
		} catch (error) {
			console.error('Error copying to clipboard', error);
		}
	};

	return (
		<button className={styles.promocode} onClick={onClickHandle}>
			<span>{code}</span>
			{isCopied ? <Check /> : <Copy />}
		</button>
	);
}
