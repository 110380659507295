import texts from '../../../texts';
import Promocode from '../../elements/promocode';
import Button from '../../elements/button';
import ResponsiveImage from '../../elements/responsiveImage';
import styles from './styles.module.scss';
import { Star } from '../../icons';

export default function CTA({ dairy = false }) {
	const { title, text, textDairy, promoCode, linkText, linkUrl, legalText, legalLink, legalUrl, legalUrlDiary } = texts.cta;
	return (
		<section className={styles.cta}>
			<div className={styles.ctaImgWrapper}>
				<ResponsiveImage
					srcS="/cta/cta.png"
					srcL="/cta/cta.png"
					wS={290}
					hS={360}
					wL={319}
					hL={390}
					alt="Барни и персик"
				/>
				<Star className={styles.ctaStar} />
			</div>
			{!dairy && <span className={styles.ctaTitle}>{title}</span>}
			<p className={styles.ctaText}>{dairy ? textDairy : text}</p>
			{dairy && <Promocode code={promoCode} />}
			<Button text={linkText} href={linkUrl} />
			<div className={styles.ctaLigalWrapper}>
				<span className={styles.ctaLigal}>{legalText}</span>
				<a className={styles.ctaLigalLink} href={dairy ? legalUrl : legalUrlDiary} target='_blank' rel="noreferrer">
					{legalLink}.
				</a>
			</div>
		</section>
	);
}
