import styles from "./styles.module.scss";

export default function Logo() {
  return (
    <img
      src="/samokat-logo.svg"
      alt="Samokat Logo"
      className={styles.logo}
      width={180}
      height={26.75}
    />
  );
}
