import ResponsiveImage from '../../elements/responsiveImage';
import Logo from '../../elements/logo';
import Button from '../../elements/button';
import texts from '../../../texts.js';
import styles from './styles.module.scss';

export default function Hero({ dairy = false }) {
	const { title, text, srcS, srcL, button, pdf } = texts.hero;
	return (
		<section className={styles.hero}>
			<Logo />
			<div className={styles.heroContent}>
				<ResponsiveImage
					className={styles.heroImage}
					srcS={srcS}
					srcL={srcL}
					wS={356}
					hS={270}
					wL={570}
					hL={404}
					alt={text}
				/>
				<div className={styles.heroTextWrapper}>
					<h1 className={styles.heroTitle}>{title}</h1>
					<p className={styles.heroText}>{text}</p>
					{dairy && (
						<Button
							className={styles.heroButton}
							href={pdf}
							text={button}
							type="white"
							download="Дневник_от_Самоката.pdf"
						/>
					)}
				</div>
			</div>
		</section>
	);
}
