export function Copy() {
	return (
		<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
			<clipPath id="a">
				<path d="m0 0h24v24h-24z" />
			</clipPath>
			<g clipPath="url(#a)" stroke="#ff335f" strokeWidth="2">
				<path
					d="m15.4284 5.14314v-.17143c0-1.44014 0-2.16021-.2803-2.71026-.2465-.48385-.6399-.87723-1.1237-1.12376-.5502-.280268-1.2702-.280268-2.7103-.280268h-6.34288c-1.44014 0-2.16021 0-2.71026.280268-.48385.24653-.87723.63991-1.12376 1.12376-.280266.55005-.280266 1.27012-.280266 2.71026v6.34289c0 1.4401 0 2.1601.280266 2.7103.24653.4838.63991.8772 1.12376 1.1237.55005.2803 1.27012.2803 2.71026.2803h.17143"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path d="m20.5713 8.57129h-9.4286c-1.42014 0-2.57141 1.15127-2.57141 2.57141v9.4286c0 1.4202 1.15127 2.5714 2.57141 2.5714h9.4286c1.4202 0 2.5714-1.1512 2.5714-2.5714v-9.4286c0-1.42014-1.1512-2.57141-2.5714-2.57141z" />
			</g>
		</svg>
	);
}

export function Check() {
	return (
		<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
			<clipPath id="a">
				<path d="m0 0h24v24h-24z" />
			</clipPath>
			<mask id="b" height="24" maskUnits="userSpaceOnUse" width="24" x="0" y="0">
				<path d="m24 0h-24v24h24z" fill="#fff" />
			</mask>
			<g clipPath="url(#a)">
				<g mask="url(#b)">
					<path
						d="m22.9547 4.72852-14.38307 14.38308-7.714208-7.7142"
						stroke="#ff335f"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					/>
				</g>
			</g>
		</svg>
	);
}

export function Line({ className }) {
	return (
		<svg className={className} width="124" height="163" viewBox="0 0 124 163" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M6.73909 1.11886C-5.04376 30.8549 1.68211 68.3169 23.4497 91.6961C47.7326 117.777 88.2815 118.042 114.187 93.7351C115.012 92.9615 115.817 92.1661 116.602 91.3511C119.775 88.0539 122.786 83.8803 122.25 79.3329C119.716 57.859 87.6398 87.2802 82.7153 92.7677C65.9605 111.438 55.7553 136.119 55.1637 161.277" stroke="black" stroke-width="1.6" stroke-miterlimit="10" stroke-linecap="round" />
		</svg>
	);
}

export function Leafs({ className }) {
	return (
		<svg className={className} width="62" height="56" viewBox="0 0 62 56" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0 0.965543C0 0.965543 4.10475 25.7569 19.179 38.9188C33.2032 51.1611 57.5687 54.5585 60.8928 54.9704C61.1425 55.0021 61.2729 55.0169 61.2729 55.0169C61.2729 55.0169 61.287 54.9478 61.3082 54.8138C52.7778 45.6079 24.393 15.8945 0 0.965543Z" fill="#92B526" />
			<path d="M0 0.966797C24.3929 15.8958 52.7778 45.6092 61.3082 54.8158C61.6685 52.5649 64.4391 31.9869 47.7218 18.3533C30.0166 3.91649 0 0.966797 0 0.966797Z" fill="url(#paint0_linear_606_1401)" />
			<defs>
				<linearGradient id="paint0_linear_606_1401" x1="62.094" y1="54.15" x2="9.80011" y2="-7.36245" gradientUnits="userSpaceOnUse">
					<stop stop-color="#5F7A32" />
					<stop offset="1" stopColor="#92B526" />
				</linearGradient>
			</defs>
		</svg>
	);
}

export function Lines({ className }) {
	return (
		<svg className={className} width="49" height="51" viewBox="0 0 49 51" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M21.8647 1.08398L23.5699 17.8168" stroke="black" stroke-width="1.6" stroke-miterlimit="10" stroke-linecap="round" />
			<path d="M25.4067 32.8145L27.1118 49.5472" stroke="black" stroke-width="1.6" stroke-miterlimit="10" stroke-linecap="round" />
			<path d="M0.876953 27.7214L17.6097 26.0156" stroke="black" stroke-width="1.6" stroke-miterlimit="10" stroke-linecap="round" />
			<path d="M30.8135 24.6726L47.5463 22.9668" stroke="black" stroke-width="1.6" stroke-miterlimit="10" stroke-linecap="round" />
		</svg>
	);
}

export function Star({ className }) {
	return (
		<svg className={className} width="44" height="48" viewBox="0 0 44 48" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M43.2252 28.7983C43.1286 28.756 33.5039 24.5476 30.6917 21.4463C28.1821 18.6778 27.058 14.6802 27.6087 10.4789C27.998 7.50523 28.6142 1.08262 28.6199 1.01775L26.7978 0.548828C26.771 0.611588 24.0737 6.82546 22.1409 10.0361C17.826 17.2026 8.09057 19.6298 0.704707 18.3224L0.711077 18.3238L0.672944 18.3175L0.285156 20.1798C5.58938 21.5676 10.2321 25.0637 13.0232 29.7813C15.6097 34.1554 16.4023 41.2225 14.9511 46.9668L15.8741 47.2001L16.7971 47.4336C16.7971 47.4336 16.7972 47.4315 16.7979 47.4308C17.2119 45.7969 19.0995 42.4594 20.007 40.8552L20.1396 40.6203C21.0958 38.9272 22.2734 36.952 23.7571 35.1384C26.4642 31.8276 29.9808 30.1635 33.9269 30.3264C35.1363 30.3764 42.501 30.6134 42.8141 30.6233L43.2252 28.7983Z" fill="#EDDD9D" />
		</svg>
	);
}