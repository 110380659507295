import { useState, useEffect } from 'react';

function ResponsiveImage({ srcS, srcL, wS, hS, wL, hL, alt }) {
	const [windowWidth, setWindowWidth] = useState(undefined);
	const [small, setSmall] = useState(false);

	useEffect(() => {
		function handleResize() {
			setWindowWidth(window.innerWidth);
		}

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (windowWidth < 768) {
			setSmall(true);
		} else {
			setSmall(false);
		}
	}, [windowWidth]);

	return (
		<img src={small ? srcS : srcL} alt={alt} width={small ? wS : wL} height={small ? hS : hL} />
	);
}

export default ResponsiveImage;
