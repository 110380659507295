import styles from './styles.module.scss';

export default function Button({ text, onClick, href, className, download, type = 'pink' }) {
	const buttonClass = `${styles.button} ${type ? styles[type] : ''} ${className || ''}`;
	if (href) {
		return (
			<a href={href} className={buttonClass} download={download}>
				{text}
			</a>
		);
	}

	return (
		<button onClick={onClick} className={buttonClass}>
			{text}
		</button>
	);
}
