import ResponsiveImage from '../../elements/responsiveImage';
import { Line, Leafs, Lines } from '../../icons';
import texts from '../../../texts';
import styles from './styles.module.scss';

const additionalElement = {
	'1': <Leafs className={styles.productsLeafs} />,
	'2': <Line className={styles.productsLine} />,
	'3': <Lines className={styles.productsLines} />
};

export default function Products({ dairy = false }) {
	const { text, textDairy, items } = texts.products;
	return (
		<section className={styles.products}>
			<p className={styles.productsText}>{dairy ? textDairy : text}</p>
			<div className={styles.productsList}>
				{Object.keys(items).map(key => {
					const { srcS, srcL, text } = texts.products.items[key];
					return (
						<div key={key} className={styles.productsItem}>
							<ResponsiveImage
								srcS={srcS}
								srcL={srcL}
								wS={312}
								hS={362}
								wL={356}
								hL={414}
								alt={text}
							/>
							{additionalElement[key]}
							<p className={styles.productsItemText}>{text}</p>
						</div>
					);
				})}
			</div>
		</section>
	);
}
