const texts = {
	hero: {
		title: 'Дневник юного любителя растений',
		text: 'Весна\u00A0— самое время, чтобы наблюдать за\u00A0зелёной травой на\u00A0улице или цветами на\u00A0подоконнике. А\u00A0ещё чтобы вырастить из\u00A0семечка настоящий лимон дома! Для\u00A0этого мы с\u00A0Барни приготовили подсказки и\u00A0дневник-раскраску\u00A0— так интереснее следить за\u00A0растениями.',
		srcS: '/hero/hero-s.png',
		srcL: '/hero/hero-l.png',
		button: 'Скачать дневник',
		pdf: '/Дневник_от_Самоката.pdf',
	},
	products: {
		text: 'Для экспериментов и\u00A0других занятий пригодятся силы. Попробуйте бисквиты «Медвежонок\u00A0Барни» с\u00A0разными вкусами.',
		textDairy:
			'Пока ждёте первых ростков, можно подкрепиться. Попробуйте разные вкусы бисквитов «Медвежонок\u00A0Барни».',
		items: {
			1: {
				srcS: '/products/1-s.png',
				srcL: '/products/1-l.png',
				text: 'Бисквит с\u00A0молочной начинкой удобно взять с\u00A0собой на\u00A0прогулку. Например, чтобы искать облака необычной формы и\u00A0перепрыгивать лужи было веселее.',
			},
			2: {
				srcS: '/products/2-s.png',
				srcL: '/products/2-l.png',
				text: 'Когда закончатся силы после бега или\u00A0рисования, выручит небольшой перекус. Советуем попробовать бисквит с\u00A0шоколадной начинкой.',
			},
			3: {
				srcS: '/products/3-s.png',
				srcL: '/products/3-l.png',
				text: 'Если поедете отдыхать на\u00A0дачу, захватите с\u00A0собой бисквит с\u00A0клубникой. Можно съесть в\u00A0дороге или\u00A0после барбекю.',
			},
		},
	},
	cta: {
		title: 'Получите дневник-раскраску\u00A0за\u00A01\u00A0₽',
		text: 'Для этого добавьте в\u00A0корзину три\u00A0любых бисквита и\u00A0дневник.',
		textDairy: 'Дарим скидку 100\u00A0₽ — при\u00A0заказе трёх любых бисквитов в\u00A0Самокате. Для\u00A0этого используйте промокод:',
		promoCode: 'SEMECHKO',
		linkText: 'Заказать',
		linkUrl: 'https://samokat.ru/category/89a00327-c39f-4b57-adef-28f549471a3a?showcaseType=MINIMARKET',
		legalText: 'Действует по 31 июля 2024. Подробнее — ',
		legalLink: 'в\u00A0правилах',
		legalUrl: 'https://terms.samokat.ru/promo/Pravila_Samokat_Dnevnik.pdf',
		legalUrlDiary: 'https://terms.samokat.ru/promo/Pravila_Samokat_Promokod_SEMECHKO.pdf',
	},
	footer: {
		legal: [
			"Акция проходит с\u00A012.07.2024 по\u00A031.07.2024 г. Информацию о\u00A0порядке проведения акции, сроке и\u00A0количестве товара, участвующего в\u00A0акции, уточняйте в\u00A0",
			" Продавец ООО\u00A0«Умный ритейл», ОГРН\u00A01177847261602,\u00A0121087, г.\u00A0Москва, ул.\u00A0Барклая, д.\u00A06 стр.\u00A03 , помещ.\u00A08Н/4. Зоны доставки и\u00A0точное время доставки уточняйте в\u00A0мобильном приложении «Самокат». ",
			" для\u00A0«Умного ритейла»."
		],
		rulesText: "Правилах\u00A0акции.",
		rulesLink: "https://terms.samokat.ru/promo/Pravila_Samokat_Dnevnik.pdf",
		rulesLinkDairy: "https://terms.samokat.ru/promo/Pravila_Samokat_Promokod_SEMECHKO.pdf",
		privacyPolicyText: "Политика обработки персональных данных",
		privacyPolicyLink: "https://samokat.ru/privacy",
		copy: "Сделано в\u00A0",
		groznovLinkName: "GROZNOV",
		groznovLinkAdress: "https://groznov.co/"
	},
};

export default texts;
