import Products from '../../sections/productsSection';
import Hero from '../../sections/heroSection';
import CTA from '../../sections/ctaSection';
import Footer from '../../sections/footer';
import styles from './styles.module.scss';

export default function Main() {
	return (
		<main className={styles.main}>
			<Hero />
			<Products />
			<CTA />
			<Footer />
		</main>
	);
}
